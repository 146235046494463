import React from 'react'
import styled from 'styled-components'
import { hsl, hsla } from 'polished'

const Wrapper = styled.a`
  padding: 18px 30px;
  width: 320px;
  max-width: 100%;
  background-color: ${hsl(200, 0.99, 0.5)};
  color: #fff;
  border: none;
  line-height: 1;
  display: inline-block;
  font-weight: 500;
  transition: all 200ms linear;
  letter-spacing: 1.2px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  margin: 10px;
  box-shadow: 1px 10px 20px 0px ${hsla(200, 0.99, 0.5, 0.4)};

  &:hover {
    background-color: ${hsl(198, 1, 0.35)};
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`

const ButtonLinkExternal = ({ ...rest }) => <Wrapper {...rest} />

export default styled(ButtonLinkExternal)``
