import React, { useContext } from 'react'
import styled from 'styled-components'
import gql from "graphql-tag"
import { Mutation } from 'react-apollo'
import Spinner from 'reactjs-simple-spinner'

import Layout from '../components/layout'
import { Hero } from '../components/Hero'
import { H1 } from '../components/Headlines'
import SEO from '../components/seo'
import Section from '../components/Section'
import ButtonLinkExternal from '../components/ButtonLinkExternal'
import HeaderImage from '../images/a-class-limousine/header-a-class-limousine.jpeg'
import { AppContext } from '../contexts/AppContext'

const Wrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  padding: 20px 0;
`
class DoMutation extends React.Component {
  componentDidMount() {
    const { mutate } = this.props
    mutate()
  }

  render() {
    return null
  }
}

const ThankYouPage = () => {
  const {
    car,
    salutation,
    title,
    firstName,
    lastName,
    street,
    zipCode,
    city,
    mobileNumber,
    phoneNumber,
    brand,
    modell: model,
    vehicleProcurement,
    procurementMethod,
    dateDay: date,
    dateTime,
    email: eMail
  } = useContext(AppContext)

  if (dateTime) {
    date.setHours(dateTime.split(':')[0]);
    date.setMinutes(dateTime.split(':')[1]);
    date.setSeconds(0);
  }

  let payment = null;

  switch (procurementMethod) {
    case 'procurement-finance':
      payment = 'Finanzierung'
      break;
    case 'procurement-lease':
      payment = 'Leasing'
      break;
    case 'procurement-buy':
      payment = 'Kauf'
      break;
    default:
      break;
  }

  const planned = vehicleProcurement === 'vehicle-procurement-yes' ? true : false;

  const testDriveMutationVariables = {
    data: {
      car,
      date,
      currentVehicle: {
        brand,
        model
      },
      planning: {
        planned,
        payment
      },
      personalData: {
        salutation,
        title,
        firstName,
        lastName,
        street,
        zipCode,
        city,
        mobileNumber,
        phoneNumber,
        eMail
      }
    },
  }

  console.log('testDriveMutationVariables', testDriveMutationVariables)

  const TESTDRIVE_MUTATION = gql`
    mutation CreateTestDrive($data: CreateTestDriveInput!) {
      createTestDrive(data: $data) {
        _id
      }
    }
  `

  return (
    <Layout>
      <SEO title="Danke" keywords={[`sug`, `probefahrt`]} />

      <Hero bgImage={HeaderImage} />

      <Section variant="grey" align="center">
        <Mutation mutation={TESTDRIVE_MUTATION} variables={testDriveMutationVariables}>
          {(mutate, { loading, error, data }) => (
            <>
              <DoMutation mutate={mutate} />

              {loading && (
                <Spinner size="medium" message="Ihr Wunschtermin wird übertragen ..." />
              )}
              {error && (
                <>
                  <H1>{salutation === 'Divers' ? 'Entschuldige ' + firstName + ' ' + lastName : 'Entschuldigen Sie ' + salutation + ' ' + lastName}{' '} - leider gab es einen Fehler.</H1>

                  <p>Bitte versuchen Sie es später erneut.</p>
                </>
              )}
              {data && (
                <>
                  <H1>Vielen Dank {salutation === 'Divers' ? firstName + ' ' + lastName : salutation + ' ' + lastName}{' '} - Ihr Wunschtermin wird bearbeitet.</H1>

                  <p>Wir werden uns in Kürze bei Ihnen per Mail oder telefonisch melden.</p>

                  <ButtonLinkExternal href="https://www.sug.de"> weiter zu S&amp;G</ButtonLinkExternal>
                </>
              )}
            </>
          )}
        </Mutation>
        <Wrapper />
      </Section>
    </Layout>
  )
}

export default ThankYouPage
